const activeStageReducer = (state = 1, action) => {
  switch (action.type) {
    case "PROCEED_FURTHER":
      return ++state;
    case "GO_BACK":
      return --state;
    case "CHANGE_STAGE":
      return state = action.payload;
    default:
      return state
  }
};

export default activeStageReducer;