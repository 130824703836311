import React from "react";
import { changeStage } from '../actions';
import { useDispatch } from "react-redux";

const StageButton = props => {

  const dispatch = useDispatch();

  return (
    <>
      <button onClick={() => {dispatch(changeStage(props.stage))}} className={props.disabled ? "stageButton stageButton-disabled" : "stageButton"} type="button">{props.children}</button>
    </>
  );
};

export default StageButton;
