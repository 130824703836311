const initialState = {
  fullName: "",
  email: "",
  service: "",
  username: "",
  description: "",
  image: null,
  QRCodeLink: "",
  frameIndent: 0,
  fullUsername: "",
}

const personInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_PERSON_FRAME_INDENT":
      return {
        ...state,
        frameIndent: action.payload
      }
    case "UPDATE_PERSON_FULLNAME":
      return {
        ...state,
        fullName: action.payload
      }
    case "UPDATE_PERSON_EMAIL":
      return {
        ...state,
        email: action.payload.trim()
      }
    case "UPDATE_PERSON_USERNAME":
      switch (state.service) {
        case "telegram":
          return {
            ...state,
            username: action.payload,
            QRCodeLink: "tg://resolve?domain=" + action.payload,
          }
        case "whatsApp":
          return {
            ...state,
            username: action.payload,
            QRCodeLink: "whatsapp://send/?phone=" + action.payload.replace(/\s|[(]|[)]/g,''),
          }
        case "facebook":
          return {
            ...state,
            username: action.payload.indexOf('https://') === 0 ? action.payload.split('/')[3] : action.payload.split('/')[1],
            QRCodeLink: action.payload.indexOf('https://') === 0 ? "m.me/" + action.payload.split('/')[3] : "m.me/" + action.payload.split('/')[1],
            fullUsername: action.payload,
          }
        case "vkontakte":
          return {
            ...state,
            username: action.payload.indexOf('https://') === 0 ? action.payload.split('/')[3] : action.payload.split('/')[1],
            QRCodeLink: action.payload.indexOf('https://') === 0 ? "vk.me/" + action.payload.split('/')[3] : "vk.me/" + action.payload.split('/')[1],
            fullUsername: action.payload,
          }
        default:
          return state;
      }
    case "UPDATE_PERSON_SERVICE":
      return {
        ...state,
        service: action.payload
      }
    case "UPDATE_PERSON_DESCRIPTION":
      return {
        ...state,
        description: action.payload
      }
    case "UPDATE_PERSON_IMAGE":
      return {
        ...state,
        image: action.payload
      }
    default:
      return state
  }
};

export default personInfoReducer;