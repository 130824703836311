import React from "react";
import InputMask from "react-input-mask";

const CustomTextfield = ({ title, placeholder, onChange, style, value, mask }) => {
  return (
    <div style={style}>
      <label htmlFor="fname" className="inputFieldTitle">
        {title}
      </label>
      <InputMask
        mask={mask}
        type="text"
        className="inputField"
        id="fname"
        maxLength="45"
        name="fname"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
    </div>
  );
};

export default CustomTextfield;