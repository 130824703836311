import React, { useEffect } from "react";
import ReactDOM from 'react-dom';

const Modal = (props) => {

    const el = document.createElement('div');
    const parent = document.getElementById('services');

    useEffect(() => {
        parent.appendChild(el);

        return function cleanup() {
            parent.removeChild(el);
        }
    })

    return ReactDOM.createPortal(props.children, el);

};

export default Modal;