import React, { useState } from "react";
import Modal from "./Modal";
import HintImg from "./CreatingBusinessCard/media/hint.png";

const Hint = ({ hintMessage }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => {
    !showModal ? setShowModal(true) : setShowModal(false);
  };

  const modal = showModal ? (
    <Modal>
      <div className="portal">
        <p className="hintText">{hintMessage}</p>
      </div>
    </Modal>
  ) : null;

  return (
    <>
      {modal}
      <img
        src={HintImg}
        onClick={handleShow}
        width="29px"
        style={{ marginLeft: "7px", cursor: "pointer" }}
      />
    </>
  );
};

export default Hint;
