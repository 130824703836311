import React from "react";

const styles = {
  hr: {
    display: "inline-block",
    width: "50px",
    marginLeft: "6px",
    marginRight: "6px",
    marginTop: '5px',
    border: "none",
    height: "1px",
    backgroundColor: "#0DCF9B",
    verticalAlign: 'middle',
    opacity: '1',
  },
  hrDisabled: {
    display: "inline-block",
    width: "50px",
    marginLeft: "6px",
    marginRight: "6px",
    marginTop: '5px',
    border: "none",
    height: "1px",
    backgroundColor: "#0DCF9B",
    verticalAlign: 'middle',
    opacity: '0.2',
  },
  container: {
    display: 'inline-block',
    textAlignment: 'center',
    verticalAlign: 'middle',
  },
};

const HorizontalLine = ({disabled}) => {
  return (
    <div style={styles.container}>
      <hr style={disabled ? styles.hrDisabled : styles.hr} />
    </div>
  );
};

export default HorizontalLine;
