import React, { useState } from "react";
import Mockup from "./images/iPhoneMockup.png";
import iPhoneLeft from "./images/iPhoneLeft.png";
import iPhoneRight from "./images/iPhoneRight.png";
import Logo from "./images/Logo.png";
import "./App.css";
import CreatingBusinessCard from "./components/CreatingBusinessCard";
import SubscriptionPage from "./components/SubscriptionPage";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { createBrowserHistory } from "history";
import messages from "./locales/messages";
import trees from "./images/trees.png";
import { useSelector, useDispatch } from "react-redux";
import { IntlProvider, FormattedMessage } from "react-intl";
import { changeLanguage } from "./actions";
export const history = createBrowserHistory();

function App() {
  const language = useSelector((state) => state.language);
  const dispatch = useDispatch();

  return (
    <IntlProvider
      key={language.language}
      locale={language.language}
      messages={messages[language.language]}
    >
      <Router history={history}>
        <div className="App">
          <Switch>
            <Route
              path="/creating-business-card"
              component={CreatingBusinessCard}
            />
            <Route path="/friend" component={CreatingBusinessCard} />
            {/* <Route path="/subscription" component={SubscriptionPage} /> */}
            <div className="mainPage">
              <div className="LogoContainer">
                <img
                  className="Logo"
                  alt="Logo"
                  width="92"
                  height="36"
                  src={Logo}
                />
                <button
                  className="changeLanguageButton"
                  type="button"
                  onClick={() => {
                    dispatch(changeLanguage());
                  }}
                >
                  {language.language === "ru" ? "ENG" : "RU"}
                </button>
              </div>
              {language.language === "ru" && (
                <h1
                  className="title"
                  style={{ fontSize: "25px", justifyContent: "left" }}
                >
                  <FormattedMessage id="take_care" />
                  <img
                    src={trees}
                    width="150px"
                    style={{ marginLeft: "10px" }}
                  />
                </h1>
              )}
              {language.language === "en" && (
                <>
                  <h1 className="title" style={{ fontSize: "25px" }}>
                    <FormattedMessage id="take_care" />
                  </h1>
                  <h1
                    className="title"
                    style={{ fontSize: "25px", justifyContent: "left" }}
                  >
                    <FormattedMessage id="forest" />
                    <img
                      src={trees}
                      width="150px"
                      style={{ marginLeft: "10px" }}
                    />
                  </h1>
                </>
              )}
              <h1 className="title" style={{ fontSize: "25px" }}>
                <FormattedMessage id="create_cards" />
              </h1>
              <p className="appDescription">
                <FormattedMessage id="main_text" />
              </p>
              <div className="iPhoneMockups">
                <img
                  className="mockup"
                  style={{ opacity: "0.6" }}
                  alt="mockup"
                  width="90"
                  height="180"
                  src={iPhoneLeft}
                />
                <img
                  className="mockup"
                  alt="mockup"
                  width="120"
                  height="220"
                  src={Mockup}
                />
                <img
                  className="mockup"
                  style={{ opacity: "0.6" }}
                  alt="mockup"
                  width="90"
                  height="180"
                  src={iPhoneRight}
                />
              </div>
              <Link to="/creating-business-card">
                <button className="createBusinessCardButton">
                  <FormattedMessage id="create_visit" />
                </button>
              </Link>
              {/* <Link to="/subscription">
                <button className="createBusinessCardButton">Подписка</button>
              </Link> */}
            </div>
          </Switch>
        </div>
      </Router>
    </IntlProvider>
  );
}

export default App;
