import React from "react";

const Textfield = ({ title, placeholder, onChange, value, style }) => {
  return (
    <div style={style}>
      <label htmlFor="fname" className="inputFieldTitle">
        {title}
      </label>
      <input
        type="text"
        className="inputField"
        id="fname"
        maxLength="45"
        name="fname"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default Textfield;
