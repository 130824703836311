import CardBackgroundBlackish from "../components/CreatingBusinessCard/card-backgrounds/card-background-blackish.png";
import CardBackgroundSpaceDark from "../components/CreatingBusinessCard/card-backgrounds/card-background-spaceDark.png";
import CardBackgroundSpaceLight from "../components/CreatingBusinessCard/card-backgrounds/card-background-spaceLight.png";
import CardBackgroundMulti from "../components/CreatingBusinessCard/card-backgrounds/card-background-multi.png";
import CardBackgroundGreyish from "../components/CreatingBusinessCard/card-backgrounds/card-background-greyish.png";

const initialState = {
  bgName: "blackish",
  bgImageObject: CardBackgroundBlackish
}

const bgReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_BG":
      switch (action.payload) {
        case "blackish":
          return {
            bgName: "blackish",
            bgImageObject: CardBackgroundBlackish
          }
        case "spaceDark":
          return {
            bgName: "spaceDark",
            bgImageObject: CardBackgroundSpaceDark
          }
        case "spaceLight":
          return {
            bgName: "spaceLight",
            bgImageObject: CardBackgroundSpaceLight
          }
        case "multi":
          return {
            bgName: "multi",
            bgImageObject: CardBackgroundMulti
          }
        case "greyish":
          return {
            bgName: "greyish",
            bgImageObject: CardBackgroundGreyish
          }
        default:
          return state;
      }
    default:
      return state
  }
};

export default bgReducer;