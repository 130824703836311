const messages = {
    en: {
        main_text: 'Use your phone screen as a digital card to exchange contacts',
        create_cards: 'Create digital cards',
        take_care: 'Take care of the',
        forest: 'forest',
        name: 'Enter your name',
        name_validation: 'It doesn\'t sound like a name',
        name_placeholder: 'John Smith',
        friend_name: 'Enter your friend\`s name',
        email: 'Enter your email',
        email_hint: 'Please enter correct mail. A card will be sent to it',
        email_placeholder: 'johnsmith@gmail.com',
        friend_email: 'Enter your friend\`s email',
        create_visit: 'Create a card for free',
        username: 'Enter your username',
        username_placeholder: 'johnsmith2077',
        friend_username: 'Enter your friend\`s username',
        phone_number: 'Enter your phone number',
        phone_number_mask: '+19999999999',
        phone_number_value: '+1   -   -    ',
        friend_phone_number: 'Enter your friend\`s phone number',
        link: 'Link to your page',
        friend_link: 'Link to your friend\`s page',
        facebook_link: 'facebook.com/john.smith2077',
        vk_link: 'vk.com/john.smith2077',
        occupation: 'Enter what you do',
        occupation_placeholder: 'Marketolog',
        friend_occupation: 'Enter what you friend\`s do',
        qrCode_button: 'Generate a QR code',
        continue: 'Continue',
        getCard: 'Get a card',
        upload_photo: 'Upload photo, which will be on your card',
        upload_friend_photo: 'Upload photo, which will be on your friend\`s card',
        replace_photo: 'Replace',
        background: 'Background',
        telegram_hint: 'To find your username, go to profile -> settings -> name',
        link_hint: 'Copy and paste the link that leads to your social network page',
        phone_hint: 'Enter your phone number that your messenger profile is registered to'
    },
    ru: {
        main_text: 'Используйте экран телефона как цифровую визитку для обмена контактами',
        create_cards: 'Создавай электронные визитки',
        take_care: 'Береги лес',
        name: 'Имя на визитке',
        name_validation: 'Кажется это не имя',
        name_placeholder: 'Иван Иванов',
        friend_name: 'Укажите имя друга',
        email: 'Почта',
        email_hint: 'Введите верную почту. На неё будет выслана визитка',
        email_placeholder: 'ivanivanov@gmail.com',
        friend_email: 'Укажите почту друга',
        create_visit: 'Создать визитку бесплатно',
        username: 'Имя пользователя',
        username_placeholder: 'ivanivanov9010',
        friend_username: 'Укажите имя пользователя друга',
        phone_number: 'Номер телефона',
        phone_number_mask: '+79999999999',
        phone_number_value: '+7 (   )          ',
        friend_phone_number: 'Укажите номер телефона друга',
        link: 'Ссылка на страницу',
        friend_link: 'Ссылка на страницу друга',
        facebook_link: 'facebook.com/ivan.ivanov9010',
        vk_link: 'vk.com/ivanivanov9010',
        occupation: 'Напишите, чем вы занимаетесь',
        occupation_placeholder: 'Маркетолог',
        friend_occupation: 'Напишите, чем занимается ваш друг',
        qrCode_button: 'Сгенерировать QR код',
        continue: 'Продолжить',
        getCard: 'Получить визитку',
        upload_photo: 'Загрузите свое фото, которое будет на визитке',
        upload_friend_photo: 'Загрузите фото, которое будет на визитке друга',
        replace_photo: 'Заменить',
        background: 'Фон',
        telegram_hint: 'Чтобы найти имя пользователя, зайдите в профиль -> настройки -> имя',
        link_hint: 'Скопируйте и вставьте ссылку, которая ведет на вашу страницу',
        phone_hint: 'Введите номер телефона, на который зарегистрирован ваш профиль в мессенджере',
    },
};

export default messages;
