const initialState = {
    language: window.navigator.language === 'ru-RU' ? 'ru' : 'en'
}

const languageReducer = (state = initialState, action) => {
    switch(action.type) {
        case "CHANGE_LANGUAGE":
            if (state.language === 'ru') {
                return {
                    language: 'en'
                }
            } else {
                return {
                    language: 'ru'
                }
            }
        default:
            return state
    }
}

export default languageReducer;