import React, { useState } from "react";
import CardBackgroundButtonBlackish from "./CreatingBusinessCard/card-backgrounds/card-background-button-blackish.png";
import CardBackgroundButtonSpaceDark from "./CreatingBusinessCard/card-backgrounds/card-background-button-spaceDark.png";
import CardBackgroundButtonSpaceLight from "./CreatingBusinessCard/card-backgrounds/card-background-button-spaceLight.png";
import CardBackgroundButtonMulti from "./CreatingBusinessCard/card-backgrounds/card-background-button-multi.png";
import CardBackgroundButtonGreyish from "./CreatingBusinessCard/card-backgrounds/card-background-button-greyish.png";
import { FormattedMessage } from "react-intl";

const parameters = {
  width: "56px",
  height: "56px",
  borderRadius: "173px",
  border: "none",
  marginRight: "5px",
  marginTop: "10px",
  cursor: "pointer",
};

const bgNames = {
  blackish: "blackish",
  spaceDark: "spaceDark",
  spaceLight: "spaceLight",
  multi: "multi",
  greyish: "greyish",
};

const styles = {
  blackish: {
    backgroundImage: `url(${CardBackgroundButtonBlackish})`,
    ...parameters,
  },
  blackishActive: {
    backgroundImage: `url(${CardBackgroundButtonBlackish})`,
    ...parameters,
    border: "2px solid #0DCF9B",
  },
  spaceDark: {
    backgroundImage: `url(${CardBackgroundButtonSpaceDark})`,
    ...parameters,
  },
  spaceDarkActive: {
    backgroundImage: `url(${CardBackgroundButtonSpaceDark})`,
    ...parameters,
    border: "2px solid #0DCF9B",
  },
  spaceLight: {
    backgroundImage: `url(${CardBackgroundButtonSpaceLight})`,
    ...parameters,
  },
  spaceLightActive: {
    backgroundImage: `url(${CardBackgroundButtonSpaceLight})`,
    ...parameters,
    border: "2px solid #0DCF9B",
  },
  multi: {
    backgroundImage: `url(${CardBackgroundButtonMulti})`,
    ...parameters,
  },
  multiActive: {
    backgroundImage: `url(${CardBackgroundButtonMulti})`,
    ...parameters,
    border: "2px solid #0DCF9B",
  },
  greyish: {
    backgroundImage: `url(${CardBackgroundButtonGreyish})`,
    ...parameters,
    marginRight: '0px',
  },
  greyishActive: {
    backgroundImage: `url(${CardBackgroundButtonGreyish})`,
    ...parameters,
    marginRight: '0px',
    border: "2px solid #0DCF9B",
  },
};

const initialState = {
  blackish: false,
  spaceDark: false,
  spaceLight: false,
  multi: false,
  greyish: false,
};

const BackgroundRow = ({ updateBg, activeBg }) => {
  const [bgs, setBg] = useState({ ...initialState, [activeBg.bgName]: true });

  const activateBg = (bgName) => {
    updateBg(bgName);
    switch (bgName) {
      case bgNames.blackish:
        setBg({ ...initialState, blackish: true });
        break;
      case bgNames.spaceDark:
        setBg({ ...initialState, spaceDark: true });
        break;
      case bgNames.spaceLight:
        setBg({ ...initialState, spaceLight: true });
        break;
      case bgNames.multi:
        setBg({ ...initialState, multi: true });
        break;
      case bgNames.greyish:
        setBg({ ...initialState, greyish: true });
        break;
      default:
        break;
    }
  };

  return (
    <div>
      {window.screen.width * window.devicePixelRatio > 700 &&
        <label className="backgroundChoosingLabel"><FormattedMessage id="background" /></label>
      }
      <div className="backgroundButtons">
        <button
          style={bgs.blackish ? styles.blackishActive : styles.blackish}
          onClick={() => activateBg(bgNames.blackish)}
          type="button"
        ></button>
        <button
          style={bgs.spaceDark ? styles.spaceDarkActive : styles.spaceDark}
          onClick={() => activateBg(bgNames.spaceDark)}
          type="button"
        ></button>
        <button
          style={bgs.spaceLight ? styles.spaceLightActive : styles.spaceLight}
          onClick={() => activateBg(bgNames.spaceLight)}
          type="button"
        ></button>
        <button
          style={bgs.multi ? styles.multiActive : styles.multi}
          onClick={() => activateBg(bgNames.multi)}
          type="button"
        ></button>
        <button
          style={bgs.greyish ? styles.greyishActive : styles.greyish}
          onClick={() => activateBg(bgNames.greyish)}
          type="button"
        ></button>
      </div>
    </div>
  );
};

export default BackgroundRow;
