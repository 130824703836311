export const proceedFurther = () => {
  return {
    type: 'PROCEED_FURTHER'
  };
};

export const goBack = () => {
  return {
    type: 'GO_BACK'
  };
};

export const updateBg = (bgName) => {
  return {
    type: 'UPDATE_BG',
    payload: bgName,
  };
};

export const setLoading = () => {
  return {
    type: 'TOGGLE_LOADING'
  };
};

export const updatePersonFullname = (event) => {
  return {
    type: 'UPDATE_PERSON_FULLNAME',
    payload: event.target.value,
  };
};

export const updatePersonEmail = (event) => {
  return {
    type: 'UPDATE_PERSON_EMAIL',
    payload: event.target.value,
  };
};

export const updatePersonUsername = (event) => {
  return {
    type: 'UPDATE_PERSON_USERNAME',
    payload: event.target.value,
  };
};

export const updatePersonService = (serviceName) => {
  return {
    type: 'UPDATE_PERSON_SERVICE',
    payload: serviceName,
  };
};

export const updatePersonDescription = (event) => {
  return {
    type: 'UPDATE_PERSON_DESCRIPTION',
    payload: event.target.value,
  };
};

export const updatePersonImageFile = (imgFile) => {
  return {
    type: 'UPDATE_PERSON_IMAGE',
    payload: imgFile,
  };
};

export const updatePersonFrameIndent = (indent) => {
  return {
    type: 'UPDATE_PERSON_FRAME_INDENT',
    payload: indent,
  };
};

export const changeLanguage = () => {
  return {
    type: 'CHANGE_LANGUAGE'
  };
};

export const changeStage = (stage) => {
  return {
    type: 'CHANGE_STAGE',
    payload: stage
  }
}