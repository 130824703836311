import { combineReducers } from 'redux'
import activeStageReducer from './activeStage'
import bgReducer from './bg'
import loadingReducer from './loading'
import personInfoReducer from './personInfo'
import languageReducer from './language'

const allReducers = combineReducers({
  activeStage: activeStageReducer,
  bg: bgReducer,
  loading: loadingReducer,
  personInfo: personInfoReducer,
  language: languageReducer,
});

export default allReducers;