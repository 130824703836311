const definedPhoneModels = [
  {
    vendor: 'Google',
    name: 'Pixel2',
    pixRes: [1080, 1920],
    indent: 464,
  },
  {
    vendor: 'Google',
    name: 'Pixel2XL',
    pixRes: [1440, 2560],
    indent: 1174,
  },
  {
    vendor: 'Samsung',
    name: 'SamsungGalaxyS8',
    pixRes: [1440, 2920],
    indent: 1444,
  },
  {
    vendor: 'Samsung',
    name: 'SamsungGalaxyJ3',
    pixRes: [720, 1280],
    indent: 464,
  },
  {
    vendor: 'Samsung',
    name: 'SamsungGalaxyS7',
    pixRes: [1440, 2560],
    indent: 1174,
  },
  {
    vendor: 'Samsung',
    name: 'SamsungGalaxyA3',
    pixRes: [720, 1280],
    indent: 442,
  },
  {
    vendor: 'Samsung',
    name: 'SamsungGalaxyA5',
    pixRes: [720, 1280],
    indent: 412,
  },
  {
    vendor: 'Samsung',
    name: 'SamsungGalaxyA7',
    pixRes: [1080, 1920],
    indent: 564,
  },
  {
    vendor: 'Samsung',
    name: 'SamsungGalaxyJ5',
    pixRes: [720, 1280],
    indent: 442,
  },
  {
    vendor: 'Samsung',
    name: 'SamsungSM_J810F',
    pixRes: [720, 1280],
    indent: 442,
  },
  {
    vendor: 'Samsung',
    name: 'SamsungSM_A505FN',
    pixRes: [1080, 2340],
    indent: 752,
  },
  {
    vendor: 'Samsung',
    name: 'SamsungSM_G965F',
    pixRes: [1440, 2960],
    indent: 884,
  },
  {
    vendor: 'Xiaomi',
    name: 'XiaomiRedmi4X',
    pixRes: [720, 1280],
    indent: 342,
  },
  {
    vendor: 'Xiaomi',
    name: 'XiaomiRedmi5Plus',
    pixRes: [1080, 2160],
    indent: 752,
  },
  {
    vendor: 'Xiaomi',
    name: 'XiaomiRedmiNote5',
    pixRes: [1080, 1920],
    indent: 664,
  },
  {
    vendor: 'Xiaomi',
    name: 'XiaomiMiA2Lite',
    pixRes: [1080, 2280],
    indent: 744,
  },
  {
    vendor: 'Xiaomi',
    name: 'XiaomiRedmiNote4',
    pixRes: [1080, 1920],
    indent: 654,
  },
  {
    vendor: 'Xiaomi',
    name: 'XiaomiRedmiNote7',
    pixRes: [1080, 2340],
    indent: 664,
  },
  {
    vendor: 'Huawei',
    name: 'HuaweiCOL_L29',
    pixRes: [1080, 2280],
    indent: 744,
  },
  {
    vendor: 'Huawei',
    name: 'HuaweiJSN_L21',
    pixRes: [1080, 2340],
    indent: 644,
  },
  {
    vendor: 'Huawei',
    name: 'HuaweiANE_LX1',
    pixRes: [1080, 2280],
    indent: 714,
  },
  {
    vendor: 'Huawei',
    name: 'HuaweiHRY_LX1',
    pixRes: [1080, 2280],
    indent: 724,
  },
  {
    vendor: 'Apple',
    name: 'iPhoneXSMax',
    pixRes: [1242, 2688],
    indent: 856,
  },
  {
    vendor: 'Apple',
    name: 'iPhoneXS',
    pixRes: [1125, 2436],
    indent: 830,
  },
  {
    vendor: 'Apple',
    name: 'iPhoneXR',
    pixRes: [828, 1792],
    indent: 610,
  },
  {
    vendor: 'Apple',
    name: 'iPhone8Plus',
    pixRes: [1080, 1920],
    indent: 516,
  },
  {
    vendor: 'Apple',
    name: 'iPhone8',
    pixRes: [750, 1334],
    indent: 486,
  },
  {
    vendor: 'Apple',
    name: 'iPhone5',
    pixRes: [640, 1136],
    indent: 342,
  },
];

export { definedPhoneModels };