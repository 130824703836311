import React from 'react';
import StageButton from '../StageButton';
import HorizontalLine from '../HorizontalLine';
import { useSelector } from "react-redux";

const StagesRow = ({style, className}) => {
  const activeStage = useSelector((state) => state.activeStage);
  return (
    <div style={style} className={className}>
      <StageButton stage={1} disabled={activeStage > 0 ? false : true}> 1 </StageButton>
      <HorizontalLine disabled={activeStage > 1 ? false : true}/>
      <StageButton stage={2} disabled={activeStage > 1 ? false : true}> 2 </StageButton>
      <HorizontalLine disabled={activeStage > 2 ? false : true}/>
      <StageButton stage={3} disabled={activeStage > 2 ? false : true}> 3 </StageButton>
      <HorizontalLine disabled={activeStage > 3 ? false : true}/>
      <StageButton stage={4} disabled={activeStage > 3 ? false : true}> 4 </StageButton>
    </div>
  );
}

export default StagesRow;
