import React, { useState, useEffect } from "react";
import { injectIntl, FormattedMessage } from 'react-intl';

const ImageUploader = ({ updatePersonImageFile, initialImageFile, forFriend, intl }) => {
  const [selectedFile, setSelectedFile] = useState(initialImageFile);
  const [preview, setPreview] = useState(
    initialImageFile ? URL.createObjectURL(initialImageFile) : null
  );
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    // eslint-disable-next-line consistent-return
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const handleChange = (e) => {
    if (e.target.files.length === 0) {
    } else {
      setSelectedFile(e.target.files[0]);
      updatePersonImageFile(e.target.files[0]);
    }
  };

  const onSelectFile = () => {
    const fileButton = document.getElementById("fileButton");
    fileButton.click();
  };

  const deleteImage = () => {
    updatePersonImageFile(null);
    setSelectedFile(null);
    setPreview(null);
  };

  return (
    <div>
      <p className="indicationText">
        { forFriend === true ? <FormattedMessage id="upload_friend_photo" /> : <FormattedMessage id="upload_photo" /> }
      </p>
      <input
        id="fileButton"
        type="file"
        hidden="hidden"
        onChange={handleChange}
      />
      <button className="plusButton" type="button" onClick={onSelectFile}>
        {preview ? (
          <img className="uploadedImage" alt="img" src={preview} />
        ) : (
          "+"
        )}
      </button>
      <div className="changeImageContainer">
        <button
          className="changeImage"
          onClick={onSelectFile}
          style={{visibility: preview ? 'visible' : 'hidden'}}
        >
          <FormattedMessage id="replace_photo" />
        </button>
      </div>
    </div>
  );
};

export default injectIntl(ImageUploader);
