import React, { useState } from "react";
import TelegramLogo from '../images/TelegramLogo.png';
import VkLogo from '../images/VkLogo.png';
import WhatsAppLogo from '../images/WhatsAppLogo.png';
import FacebookLogo from '../images/FacebookLogo.png';

const ServicesRow = ({updatePersonService, activeService}) => {
  const servicesName = {
    telegram: 'telegram',
    whatsApp: 'whatsApp',
    facebook: 'facebook',
    vkontakte: 'vkontakte',
  };
  const initialState = {
    telegram: false,
    whatsApp: false,
    facebook: false,
    vkontakte: false
  };
  const [services, setService] = useState({...initialState, [activeService]: true});
  const activateService = serviceName => {
    updatePersonService(serviceName);
    switch (serviceName) {
      case "telegram":
        setService({ ...initialState, telegram: true });
        break;
      case "whatsApp":
        setService({ ...initialState, whatsApp: true });
        break;
      case "facebook":
        setService({ ...initialState, facebook: true });
        break;
      case "vkontakte":
        setService({ ...initialState, vkontakte: true });
        break;
      default:
        break;
    }
  };

  return (
    <div className="servicesRow">
      <p className="chooseService">
        Выберите сервис, на который будет ссылка
      </p>
      <div className="servicesIcons">
        <div>
          <input 
            className={activeService === 'telegram' ? "serviceIcon-enabled" : 'serviceIcon'}
            type="image" 
            src={TelegramLogo}
            onClick={() => activateService(servicesName.telegram)}
          />
          <span className="caption">Telegram</span>
        </div>
        <div>
          <input 
            className={activeService === 'facebook' ? "serviceIcon-enabled" : 'serviceIcon'}
            type="image" 
            src={FacebookLogo}
            onClick={() => activateService(servicesName.facebook)}
          />
          <span className="caption">Facebook</span>
        </div>
        <div>
          <input 
            className={activeService === 'whatsApp' ? "serviceIcon-enabled" : 'serviceIcon'}
            type="image" 
            src={WhatsAppLogo}
            onClick={() => activateService(servicesName.whatsApp)}
          />
          <span className="caption">WhatsApp</span>
        </div>
        <div>
          <input 
            className={activeService === 'vkontakte' ? "serviceIcon-enabled" : 'serviceIcon'}
            type="image" 
            src={VkLogo}
            onClick={() => activateService(servicesName.vkontakte)}
          />
          <span className="caption">Vkontakte</span>
        </div>
      </div>
    </div>
  );
};

export default ServicesRow;
