import React, { useState, useEffect } from "react";
import QRCode from "qrcode.react";
import StagesRow from "./Stages";
import Textfield from "../Textfield";
import "./styles/style.css";
import ImgUploader from "../ImageUploader";
import ServicesRow from "../ServicesRow";
import BackButton from "./media/backButton.svg";
import AvatarPlaceholder from "./media/avatarPlaceholder.png";
import BackgroundRow from "../BackgroundRow";
import axios from "axios";
import FormData from "form-data";
import RingLoader from "react-spinners/RingLoader";
import UAParser from "ua-parser-js";
import Hint from '../Hint';
import { useHistory } from 'react-router-dom';
import Vector from '../../images/Vector.png';
import { injectIntl, FormattedMessage } from 'react-intl';

import CustomTextfield from '../CustomTextfield';
import { useSelector, useDispatch } from "react-redux";
import {
  setLoading,
  goBack,
  proceedFurther,
  updatePersonFrameIndent,
  updatePersonDescription,
  updatePersonEmail,
  updatePersonFullname,
  updatePersonImageFile,
  updatePersonService,
  updatePersonUsername,
  updateBg,
} from "../../actions";
import { definedPhoneModels } from "./PhoneModels";
import { inflateSync } from "mz/zlib";
// import { Form } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";

const endpoint = "https://fs.lockscreen.pro/generate-business-card"
//const endpoint = "http://localhost:5000/generate-business-card";

const Index = ({ intl }) => {
  const activeStage = useSelector((state) => state.activeStage);
  const personInfo = useSelector((state) => state.personInfo);
  const loading = useSelector((state) => state.loading);
  const bg = useSelector((state) => state.bg);
  const language = useSelector((state) => state.language)
  const dispatch = useDispatch();
  const history = useHistory();
  const [emailValidation, setEmailValidation] = useState(false);
  const forFriend = window.location.pathname === "/friend" ? true : false;
  const [nameValidation, setNameValidation] = useState(false);
  const placeholders = {
    name_placeholder: intl.formatMessage({id: 'name_placeholder'}),
    email_placeholder: intl.formatMessage({id: 'email_placeholder'}),
    username_placeholder: intl.formatMessage({id: 'username_placeholder'}),
    phone_number_placeholder: intl.formatMessage({id: 'phone_number_mask'}),
    vk_link_placeholder: intl.formatMessage({id: 'vk_link'}),
    facebook_link_placeholder: intl.formatMessage({id: 'facebook_link'}),
    occupation_placeholder: intl.formatMessage({id: 'occupation_placeholder'}),
  }

  let parser = new UAParser();
  let result = parser.getResult();

  const styles = {
    bgImage: {
      backgroundImage: `url(${bg.bgImageObject})`,
    },
    loading: {
      position: "absolute",
      transform: "translate(-50%, -50%)",
      left: "50%",
      top: "50%",
      marginLeft: "-20px",
      filter: "brightness(100%) !important",
    },
    devicePicker: {
      width: "90%",
      height: "50px",
      textAlign: "center",
      margin: "0 auto",
      background: "transparent",
      color: "white",
    },
  };
  // const options = [
  //   {
  //     text: 'sometext1',
  //     value: 'value1',
  //   },
  //   {
  //     text: 'sometext2',
  //     value: 'value2',
  //   },
  // ];

  useEffect(() => {
    const filteredByVendor = definedPhoneModels.filter(
      (model) => model.vendor === result.device.vendor
    );
    
    if (filteredByVendor.length === 0) return;
    let screenDimensions = getScreenDimensions()
    const filteredByPixRes = filteredByVendor.filter(
      (item) => item.pixRes[0] === screenDimensions.widthPixels && item.pixRes[1] === screenDimensions.heightPixels
    );
    if (filteredByPixRes.length === 0) return;

    let maxIndent = -1;
    let minIndent = 5000;
    
    for (let i = 0; i < filteredByPixRes.length; i++) {
      if (filteredByPixRes[i].indent > maxIndent)
        maxIndent = filteredByPixRes[i].indent;
      if (filteredByPixRes[i].indent < minIndent)
        minIndent = filteredByPixRes[i].indent;
    }
    const middle = minIndent + (maxIndent - minIndent) / 2;
    dispatch(updatePersonFrameIndent(middle));
  }, []);

  const proceed = () => {
    dispatch(proceedFurther());
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const getScreenDimensions = () => {
    let screenDimensions = {
      widthPixels: window.screen.width * window.devicePixelRatio,
      heightPixels: window.screen.height * window.devicePixelRatio,
    };

    if (screenDimensions.widthPixels > screenDimensions.heightPixels) {
      screenDimensions = {
        widthPixels: 1500,
        heightPixels: 2890,
      };
    }

    return screenDimensions;
  };

  const checkIfEmailValid = (email) => {
    const hasMatched = email.match(
      /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    );
    if (hasMatched) {
      return true;
    } else {
      return false;
    }
  };

  const checkIfNameValid = (fullName) => {
    const hasMatched = fullName.match(
      /^[A-Za-zа-яА-Я\s]{1,}$/i
    );
    if (hasMatched) {
      return true;
    } else {
      return false;
    }
  };

  const SubmitHandler = (event) => {
    if (activeStage !== 5) {
      event.preventDefault();
      return;
    }

    dispatch(setLoading(true));
    event.preventDefault();
    let data = new FormData();
    const screenDimensions = getScreenDimensions();
    if (personInfo.image !== null) {
      data.append("file", personInfo.image, personInfo.image.fileName);
    }
    data.append(
      "hasImage",
      personInfo.image === null ? false : true
    );
    data.append(
      "userInfo",
      JSON.stringify({ ...personInfo, bgName: bg.bgName, ...screenDimensions })
    );
    // history.push('/end');
    axios
      .post(endpoint, data, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        },
      })
      .then((response) => {
        dispatch(setLoading(false));
        forFriend === true ? alert("Успех! Созданные обои отправлены другу на почту") : alert("Успех! Созданные обои отправлены вам на почту");
        
      })
      .catch((error) => {
        dispatch(setLoading(false));
        alert("Произошла ошибка. Перепроверьте введённую Вами почту");
      });
  };

  // const handleDevicePicker = (e) => {
  //   console.log(e.target.value);
  // }

  return (
    <form onSubmit={(event) => SubmitHandler(event)}>
      <div
        className={loading ? "Main Main-disabled" : "Main"}
        id="testid"
        style={activeStage === 5 ? styles.bgImage : null}
      >
        <div className={activeStage === 5 ? "" : "subMain"}>
          <div
            className="upperPanel"
            style={{ visibility: activeStage > 1 ? "visible" : "hidden" }}
          >
            <button
              className="backButton"
              onClick={() => dispatch(goBack())}
              type="button"
            >
              <img
                className="backButton"
                alt="back"
                width="45"
                src={BackButton}
              />
            </button>
          </div>
          <StagesRow
            className="stages"
            style={{ visibility: activeStage === 5 ? "hidden" : "visible" }}
            currentStage={activeStage}
          />
          {activeStage === 1 && (
            <div className="inputArea">
              <Textfield
                title={ forFriend === true ? <FormattedMessage id="friend_name" /> : <FormattedMessage id="name" /> }
                placeholder={placeholders.name_placeholder}
                value={personInfo.fullName}
                onChange={(e) => {
                  dispatch(updatePersonFullname(e));
                }}
                style={{ marginBottom: "32px" }}
              />
              {!checkIfNameValid(personInfo.fullName) && personInfo.fullName != '' && (
                <p
                  style={{
                    color: "red",
                    textAlign: "left",
                    marginLeft: "20px",
                    marginTop: '-20px'
                  }}
                >
                  <FormattedMessage id="name_validation" />
                </p>
              )}
              <Textfield
                title={ forFriend === true ? <FormattedMessage id="friend_email" /> : <FormattedMessage id="email" /> }
                placeholder={placeholders.email_placeholder}
                value={personInfo.email}
                onChange={(e) => {
                  dispatch(updatePersonEmail(e));
                }}
              />
              {!checkIfEmailValid(personInfo.email) && (
                <p
                  style={{
                    color: "white",
                    textAlign: "left",
                    marginLeft: "20px",
                  }}
                >
                  <FormattedMessage id="email_hint" />
                </p>
              )}
              {/* <Form.Control as="select" style={styles.devicePicker} onChange={(e) => handleDevicePicker(e)}>
                {options.map((item, key) => 
                <option value={item.value} selected={key === 1 ? true : false}> {item.text} </option>
                )}
              </Form.Control> */}
            </div>
          )}
          {activeStage === 2 && (
            <div>
              <ImgUploader
                updatePersonImageFile={(e) =>
                  dispatch(updatePersonImageFile(e))
                }
                initialImageFile={personInfo.image}
                forFriend={forFriend}
              />
            </div>
          )}
          {activeStage === 3 && (
            <div id='services'>
              <ServicesRow
                updatePersonService={(e) => dispatch(updatePersonService(e))}
                activeService={personInfo.service}
              />
              {personInfo.service === 'telegram' && (
                <Textfield
                  title={ forFriend === true ? <div className="title"><FormattedMessage id="friend_username" /><Hint hintMessage={intl.formatMessage({id: 'telegram_hint'})} /></div> : <div className="title"><FormattedMessage id="username" /><Hint hintMessage={intl.formatMessage({id: 'telegram_hint'})} /></div> }
                  placeholder={placeholders.username_placeholder}
                  onChange={(e) => dispatch(updatePersonUsername(e))}
                  value={personInfo.username}
                />
              )}
              {personInfo.service === 'whatsApp' && (
                <CustomTextfield
                  title={ forFriend === true ? <div className="title"><FormattedMessage id="friend_phone_number" /><Hint hintMessage={intl.formatMessage({id: 'phone_hint'})} /></div> : <div className="title"><FormattedMessage id="phone_number" /><Hint hintMessage={intl.formatMessage({id: 'phone_hint'})} /></div> }
                  placeholder={placeholders.phone_number_placeholder}
                  onChange={(e) => dispatch(updatePersonUsername(e))}
                  value={personInfo.username}
                />
              )}
              {personInfo.service === 'vkontakte' && (
                <Textfield
                title={ forFriend === true ? <div className="title"><FormattedMessage id="friend_link" /><Hint hintMessage={intl.formatMessage({id: 'link_hint'})} /></div> : <div className="title"><FormattedMessage id="link" /><Hint hintMessage={intl.formatMessage({id: 'link_hint'})} /></div> }
                placeholder={placeholders.vk_link_placeholder}
                onChange={(e) => dispatch(updatePersonUsername(e))}
                value={personInfo.fullUsername}
                />
              )}
              {personInfo.service === 'facebook' && (
                <Textfield
                title={ forFriend === true ? <div className="title"><FormattedMessage id="friend_link" /><Hint hintMessage={intl.formatMessage({id: 'link_hint'})} /></div> : <div className="title"><FormattedMessage id="link" /><Hint hintMessage={intl.formatMessage({id: 'link_hint'})} /></div> }
                placeholder={placeholders.facebook_link_placeholder}
                onChange={(e) => dispatch(updatePersonUsername(e))}
                value={personInfo.fullUsername}
                />
              )}
            </div>
          )}
          {activeStage === 4 && (
            <div style={{ marginTop: "20px" }}>
              <Textfield
                title={ forFriend === true ? <FormattedMessage id="friend_occupation" /> : <FormattedMessage id="occupation" /> }
                placeholder={placeholders.occupation_placeholder}
                onChange={(e) => dispatch(updatePersonDescription(e))}
                value={personInfo.description}
              />
              <div className="rectangleFirstPreview">
                <img
                  alt="pic"
                  className="miniAvatar"
                  src={
                    personInfo.image
                      ? URL.createObjectURL(personInfo.image)
                      : AvatarPlaceholder
                  }
                />
                <div className="userInfo">
                  <p className="previewFullname">{personInfo.fullName}</p>
                  <div className="somediv">
                    <label className="previewDescription">
                      {placeholders.occupation_placeholder}
                    </label>
                  </div>
                </div>
                <img style={{ marginTop: '10px' }} src={Vector} />
              </div>
            </div>
            
          )}
          {activeStage === 5 && (
            <div className="previewContent">
              <div className="rectanglePreview">
                <img
                  alt="pic"
                  className="miniAvatar"
                  src={
                    personInfo.image
                      ? URL.createObjectURL(personInfo.image)
                      : AvatarPlaceholder
                  }
                />
                <div className="userInfo">
                  <p className="previewFullname">{personInfo.fullName}</p>
                  <div className="somediv">
                    <label className="previewDescription">
                      {personInfo.description}
                    </label>
                  </div>
                </div>
                <QRCode
                  className="QRCode"
                  value={personInfo.QRCodeLink}
                  level="H"
                />
              </div>
              <div className="backgroundChoosing">
                <BackgroundRow
                  updateBg={(e) => dispatch(updateBg(e))}
                  activeBg={bg}
                />
              </div>
            </div>
          )}
          {activeStage < 5 && (
            <div>
              <button
                className={
                  checkIfEmailValid(personInfo.email) && personInfo.fullName != '' && checkIfNameValid(personInfo.fullName)
                    ? "customButton"
                    : "customButton customButton-disabled"
                }
                onClick={proceed}
                type="button"
              >
                {activeStage === 4 ? <FormattedMessage id="qrCode_button" /> : <FormattedMessage id="continue" />}
              </button>
            </div>
          )}
          {activeStage === 5 && (
            <div>
              <button className="customButton" type="submit">
                <FormattedMessage id="getCard" />
              </button>
            </div>
          )}
        </div>
      </div>
      {loading && (
        <RingLoader
          css={styles.loading}
          size={250}
          color={"#0dcf9b"}
          loading={loading}
        />
      )}
    </form>
  );
};

export default injectIntl(Index);
